body {
  background-color: black;
  font-size: 30px;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#__next {
  max-width: 100vw;
  overflow-x: hidden;
}

.text-balance {
  text-wrap: balance;
}

.text-data {
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.job-description {
  ul {
    list-style: initial;
    padding-left: 2rem;
  }
}

// .testimonials .swiper-slide-next div {
//   opacity: 0.4;
// }

.latest-testimonials {
  .swiper-slide {
    height: auto;
  }
}

.operation .swiper-slide-next .item {
  opacity: 0.4;
}

.icon-0 {
  grid-area: 1 / 1 / 2 / 3;
}

.icon-1 {
  grid-area: 1 / 3 / 2 / 5;
}

.icon-2 {
  grid-area: 1 / 5 / 2 / 7;
}

.icon-3 {
  grid-area: 2 / 2 / 3 / 4;
}

.icon-4 {
  grid-area: 2 / 4 / 3 / 6;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #ff1eb7;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #ff1eb7;
}

#image-container img {
  filter: hue-rotate(0deg);
  animation: colorChangeAnimation 10s linear infinite;
}

@keyframes colorChangeAnimation {
  0% {
    filter: hue-rotate(0deg);
  }

  100% {
    filter: hue-rotate(-350deg);
  }
}

.sectors-content {
  p {
    margin: 0 0 0.825rem 0;
  }
}

.z-index-1 {
  z-index: 1;
}

.job-application-form {
  .required {
    label {
      &:after {
        content: "*Required";
        color: #51fb06;
        font-size: 0.825rem;
      }
    }
  }

  .browse {
    color: #51fb06 !important;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-6,
  .form-group {
    padding: 0 1.5rem;
  }

  .list-group {
    display: flex;
    flex-direction: column;

    .list-group-item {
      border: 1px solid #fff;

      .fa-remove {
        cursor: pointer;
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" /> </svg> ');
        width: 1.5rem;
        height: 1.5rem;
        vertical-align: sub;
        display: block;
        margin: 0.5rem 0 0 0.5rem;

        &:hover,
        &:focus,
        &:active {
          opacity: 0.5;
        }
      }

      a {
        text-decoration: underline;
        cursor: pointer;

        &:hover,
        &:focus,
        &:active {
          text-decoration: none;
        }
      }

      .row {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }

  .formio-component-columns {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0.825rem;
    padding: 0;

    .col-md-6 {
      padding: 0;
    }
  }

  .formio-form {
    .form-group {
      margin-bottom: 0.825rem;

      input {
        width: 100%;
        color: #000000;
      }
    }

    .formio-component-file {
      .list-group-item:only-child {
        display: none;
      }

      .fileSelector {
        background: lightgrey;
        padding: 40px;
        text-align: center;
        border: white 1px solid !important;
        --tw-text-opacity: 1;
        color: rgb(27 13 52 / var(--tw-text-opacity));
        font-size: 1rem;

        .browse {
          background-color: white;
          padding: 0.5rem 1rem;
        }
      }
    }

    .sr-only {
      display: none;
    }

    .browse {
      text-decoration: underline;

      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
      }
    }

    .formio-component-submit {
      .btn {
        background-color: rgb(82 255 0 / var(--tw-bg-opacity));
        padding: 0.75rem 2rem !important;
        font-size: 1.25rem;
        color: #1b0d34;
        cursor: pointer;

        &:hover,
        &:focus,
        &:active {
          opacity: 0.5;
        }
      }
    }
  }

  @media (min-width: 767px) {
    .col-md-6 {
      width: 50%;
    }
  }
}

.terms-conditions {

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: revert;
    font-weight: revert;
  }
}

.overflow-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  /* Limit the number of lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.talent-description {
  font-size: 1rem;
}

.talent-description ul {
  list-style-type: disc;
  margin-left: 1.5rem;
}

.talent-description li {
  margin-bottom: 0.5rem;
}

.our-services {
  h5 {
    margin: 0 0 1.5rem 0;
  }

  // a {
  //   color: #fff !important;

  //   &:hover,
  //   &:focus,
  //   &:active {
  //     h5 {
  //       color: #00ffff;
  //     }
  //   }
  // }
  .lead-card {
    transition: all .3s ease;

    &:hover,
    &:focus,
    &:active {
      .expanding-button {
        background: #fff !important;
        color: #150235 !important;
        padding-left: 30px !important;

        padding-right: 50px !important;

        .small-arrow {
          opacity: 1 !important;
        }
      }
    }
  }

  .sub-card {
    transition: all .3s ease;

    p {
      font-size: 20px;
      line-height: 32px;
    }

    h5 {
      font-size: 30px;
      font-weight: 400;
    }

    &:hover,
    &:focus,
    &:active {
      h5 {
        color: rgba(255, 30, 183, 1) !important;
      }
    }

  }
}

.homepage {
  &.h-screen {
    min-height: 100vh;
    height: auto;
  }

  .latest-testimonials {
    .item {
      background: rgba(0, 255, 255, 1);
    }
  }
}

.blogs-page {
  .content-join-section {
    .primary--btn {}
  }
}

.candidates-page,
.join-us-page {
  .swiper-item {
    color: #1b0d34;
  }
}

@keyframes gradient-x {

  0%,
  100% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }
}

.animate-gradient-x {
  background-size: 200% auto;
  background-image: linear-gradient(121.95deg,
      #ffffff 30.79%,
      rgba(0, 255, 255, 0.31) 169.87%);
  animation: gradient-x 3s ease infinite;
}

#dynamicContent {
  h1 {
    font-weight: 400;
    font-size: 3rem;
    line-height: 120%;
    color: #1b0d34;
  }

  h2 {
    font-weight: 400;
    font-size: 2.5rem;
    line-height: 120%;
    color: #1b0d34;
  }

  h3 {
    font-weight: 400;
    font-size: 2rem;
    line-height: 120%;
    color: #1b0d34;
  }

  h4 {
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 120%;
    color: #1b0d34;
  }

  h5 {
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 120%;
    color: #1b0d34;
  }

  h6 {
    font-weight: 400;
    font-size: 1rem;
    line-height: 120%;
    color: #1b0d34;
  }

  p {
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 160%;
    color: #1b0d34;
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  a {
    color: #007bff;
  }

  ul,
  ol {
    font-size: 1.25rem;
    line-height: 160%;
    color: #1b0d34;
    margin-left: 20px;
    list-style-type: decimal;
  }

  li {
    font-size: 1.25rem;
    line-height: 160%;
    color: #1b0d34;
    margin-left: 20px;
    list-style-type: disc;
  }

  blockquote {
    font-style: italic;
    border-left: 4px solid #1b0d34;
    padding-left: 15px;
  }

  code {
    font-family: monospace;
    background-color: #f8f9fa;
    padding: 2px 5px;
  }

}

@media (max-width: 768px) {
  #dynamicContent h1 {
    font-size: 2.5rem;
  }

  #dynamicContent h2 {
    font-size: 2rem;
  }

  #dynamicContent h3 {
    font-size: 1.75rem;
  }

  #dynamicContent h4 {
    font-size: 1.25rem;
  }

  #dynamicContent h5,
  #dynamicContent h6 {
    font-size: 1rem;
  }

  #dynamicContent p,
  #dynamicContent ul,
  #dynamicContent ol,
  #dynamicContent li {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  #dynamicContent h1 {
    font-size: 2rem;
  }

  #dynamicContent h2 {
    font-size: 1.75rem;
  }

  #dynamicContent h3 {
    font-size: 1.5rem;
  }

  #dynamicContent h4 {
    font-size: 1.25rem;
  }

  #dynamicContent h5,
  #dynamicContent h6 {
    font-size: 1rem;
  }

  #dynamicContent p,
  #dynamicContent ul,
  #dynamicContent ol,
  #dynamicContent li {
    font-size: 1rem;
  }
}

#dynamicSpecialismContent {
  h1 {
    font-weight: 400;
    font-size: 3rem;
    line-height: 120%;
    color: #ffffff;
  }

  h2 {
    font-weight: 400;
    font-size: 2.5rem;
    line-height: 120%;
    color: #ffffff;
  }

  h3 {
    font-weight: 400;
    font-size: 2rem;
    line-height: 120%;
    color: #ffffff;
  }

  h4 {
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 120%;
    color: #ffffff;
  }

  h5 {
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 120%;
    color: #ffffff;
  }

  h6 {
    font-weight: 400;
    font-size: 1rem;
    line-height: 120%;
    color: #ffffff;
  }

  p {
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 160%;
    color: #ffffff;
    margin-bottom: 10px;
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  a {
    color: #007bff;
  }

  ul,
  ol {
    font-size: 1.25rem;
    line-height: 160%;
    color: #fbff20;
    margin-left: 20px;
    list-style-type: decimal;
  }

  li {
    font-size: 1.25rem;
    line-height: 160%;
    color: #fbff20;
    margin-left: 20px;
    list-style-type: disc;
  }

  blockquote {
    font-style: italic;
    border-left: 4px solid #ffffff;
    padding-left: 15px;
  }

  code {
    font-family: monospace;
    background-color: #f8f9fa;
    padding: 2px 5px;
  }
}

@media (max-width: 768px) {
  #dynamicSpecialismContent h1 {
    font-size: 2.5rem;
  }

  #dynamicSpecialismContent h2 {
    font-size: 2rem;
  }

  #dynamicSpecialismContent h3 {
    font-size: 1.75rem;
  }

  #dynamicSpecialismContent h4 {
    font-size: 1.25rem;
  }

  #dynamicSpecialismContent h5,
  #dynamicSpecialismContent h6 {
    font-size: 1rem;
  }

  #dynamicSpecialismContent p,
  #dynamicSpecialismContent ul,
  #dynamicSpecialismContent ol,
  #dynamicSpecialismContent li {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  #dynamicSpecialismContent h1 {
    font-size: 2rem;
  }

  #dynamicSpecialismContent h2 {
    font-size: 1.75rem;
  }

  #dynamicSpecialismContent h3 {
    font-size: 1.5rem;
  }

  #dynamicSpecialismContent h4 {
    font-size: 1.25rem;
  }

  #dynamicSpecialismContent h5,
  #dynamicSpecialismContent h6 {
    font-size: 1rem;
  }

  #dynamicSpecialismContent p,
  #dynamicSpecialismContent ul,
  #dynamicSpecialismContent ol,
  #dynamicSpecialismContent li {
    font-size: 1rem;
  }
}

/* Styles for #dynamicPrivacyContent */
#dynamicPrivacyContent {
  h1 {
    font-weight: 400;
    font-size: 3rem;
    line-height: 120%;
    color: #1b0d34;
  }

  h2 {
    font-weight: 400;
    font-size: 2.5rem;
    line-height: 120%;
    color: #1b0d34;
  }

  h3 {
    font-weight: 400;
    font-size: 2rem;
    line-height: 120%;
    color: #1b0d34;
  }

  h4 {
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 120%;
    color: #1b0d34;
  }

  h5 {
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 120%;
    color: #1b0d34;
  }

  h6 {
    font-weight: 400;
    font-size: 1rem;
    line-height: 120%;
    color: #1b0d34;
  }

  p {
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 160%;
    color: #1b0d34;
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  a {
    color: #007bff;
  }

  ul,
  ol {
    font-size: 1.25rem;
    line-height: 160%;
    color: #1b0d34;
    margin-left: 10px;
    list-style-type: decimal;
  }

  li {
    font-size: 1.25rem;
    line-height: 160%;
    color: #1b0d34;
    margin-left: 10px;
    list-style-type: disc;
  }

  blockquote {
    font-style: italic;
    border-left: 4px solid #1b0d34;
    padding-left: 15px;
  }

  code {
    font-family: monospace;
    background-color: #f8f9fa;
    padding: 2px 5px;
  }
}

@media (max-width: 768px) {
  #dynamicPrivacyContent h1 {
    font-size: 2.5rem;
  }

  #dynamicPrivacyContent h2 {
    font-size: 2rem;
  }

  #dynamicPrivacyContent h3 {
    font-size: 1.75rem;
  }

  #dynamicPrivacyContent h4 {
    font-size: 1.25rem;
  }

  #dynamicPrivacyContent h5,
  #dynamicPrivacyContent h6 {
    font-size: 1rem;
  }

  #dynamicPrivacyContent p,
  #dynamicPrivacyContent ul,
  #dynamicPrivacyContent ol,
  #dynamicPrivacyContent li {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  #dynamicPrivacyContent h1 {
    font-size: 2rem;
  }

  #dynamicPrivacyContent h2 {
    font-size: 1.75rem;
  }

  #dynamicPrivacyContent h3 {
    font-size: 1.5rem;
  }

  #dynamicPrivacyContent h4 {
    font-size: 1.25rem;
  }

  #dynamicPrivacyContent h5,
  #dynamicPrivacyContent h6 {
    font-size: 1rem;
  }

  #dynamicPrivacyContent p,
  #dynamicPrivacyContent ul,
  #dynamicPrivacyContent ol,
  #dynamicPrivacyContent li {
    font-size: 1rem;
  }
}

// form

.contact_form {
  .formio-form {
    .formio-component.formio-component-htmlelement.formio-component-html {
      .formio-component-htmlelement {
        font-size: 12px;
        width: 20%;
     
      }
    }

    .table-responsive .formio-component.formio-component-table {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px; 
  }

    .row.formio-component.formio-component-columns.formio-component-columns.formio-component-label-hidden {
      font-size: 12px;
    }
  
    .form-group.has-feedback.formio-component.formio-component-selectboxes.formio-component-whichWouldYouConsiderYourselfAs {
      width: 100%;
      .field-wrapper {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
      }
    }

    .form-group {
      width: 100%;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
    }

    .form-group.has-feedback.formio-component {
      display: block;
      width: 100%;
    }

    .form-group.has-feedback.formio-component.formio-component-textfield.formio-component-yourMessage.text-area {
      width: 100%;
      height: 200px;
      box-sizing: border-box;
      display: block;
      flex-grow: 1;
    }

    .table-responsive.formio-component.formio-component-table.formio-component-table.no-top-border-table {
      display: flex;
       p {
        font-size: 12px;
        width: 50%;
       }
    }
    
    .formio-errors {
      .form-text.error {
        font-size: 0.75rem;
        color: red;
        margin-top: 10px;
        padding: 4px 4px;
        position: absolute;
        background: white;
      }
    }

    .help-block {
      font-size: 1rem;
      line-height: 1.2rem;
      color: #00ff00;
      padding: 8px 12px;
      display: flex;
      margin-top: 20px;
      position: absolute;
    }

    .sr-only,
    .list-group-item a,
    .list-group-item div {
      font-size: 1rem;
    }

    flex-direction: column;
    display: flex;

    .formio-component-textfield,
    .formio-component-phoneNumber,
    .formio-component-email {
      input {
        background-color: transparent;
        border: 0;
        border-bottom: 1px solid white;
        padding-left: 0;
        padding-bottom: 12px;
        outline: none;
      }

      input:active {
        pointer-events: none;
      }

      label {
        display: none;
      }
    }

    .formio-component-textfield input::placeholder,
    .formio-component-phoneNumber input::placeholder,
    .formio-component-email input::placeholder {
      color: white;
      text-indent: 0;
    }

    .checkbox.form-check.radio-selected input[type="checkbox"] {
      border: none;
      outline: none;
    }

    .checkbox.form-check.radio-selected input[type="checkbox"]:checked,
    .form-check-input[type="checkbox"]:checked {
      --tw-text-opacity: 1;
      background-color: rgb(27 13 52 / var(--tw-text-opacity));
      border: none !important;
      outline: none !important;
      -webkit-appearance: none;
      appearance: none;
    }

    .checkbox.form-check input[type="checkbox"] {
      outline: none !important;
      box-shadow: none !important;
    }

    .checkbox.form-check input[type="checkbox"]:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }

  .formio-form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; /* Allows items to wrap to new rows */
    gap: 10px; /* Space between items */
}

.formio-form > div {
    flex: 1 1 calc(33.3333% - 20px); /* 3 columns with space between */
}

.formio-form > div:nth-child(n + 7) {
    flex-basis: 100%; /* Spans across all columns (full width) */
}
}

.vacancy_form {
  label {
    &.field-required {
      &:after {
        position: relative;
        content: "*";
        color: red;
        display: inline-block;
      }
    }
  }

  .formio-form {
    max-width: 100%;
    padding-top: 40px;
    margin: 0 auto;
    border: 1px solid #1b0d34;
    background-color: #1b0d34;
  }

  .formio-errors {
    .form-text.error {
      font-size: 0.75rem;
      color: rgb(245, 173, 173);
      margin-top: 10px;
      padding: 4px 4px;
      position: absolute;
      background: #1b0d34;
    }
  }

  .form-group {
    margin-bottom: 20px;
  }

  .checkbox.form-check label {
    font-size: 16px;
  }

  .checkbox.form-check label[for="euyw84f-egmm7b--other"] {
    font-size: 12px;
  }

  .vacancy_form label {
    font-weight: normal;
    font-size: 1rem;
  }

  .col-form-label {
    font-weight: normal;
    font-size: 1rem;
  }

  /* Input box  */
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea {
    color: rgb(255, 255, 255);
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid transparent;
    border-bottom: 1px solid white;
  }

  input[type="text"]:focus,
  input[type="email"]:focus,
  input[type="tel"]:focus,
  textarea:focus {
    border-color: #1b0d34;
    outline: none;
  }

  /* Label styles */
  label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
  }

  .formio-select-autocomplete-input {
    display: none;
  }

  .choices {
    position: relative;
    overflow: hidden;
    margin-bottom: 24px;
    font-size: 16px;
  }

  .choices__list--dropdown {
    color: #000000;
  }

  .choices:focus {
    outline: 0;
  }

  .choices:last-child {
    margin-bottom: 0;
  }

  .choices.is-open {
    overflow: visible;
  }

  .choices.is-disabled .choices__inner,
  .choices.is-disabled .choices__input {
    background-color: #eaeaea;
    cursor: not-allowed;
    -webkit-user-select: none;
    user-select: none;
  }

  .choices.is-disabled .choices__item {
    cursor: not-allowed;
  }

  .choices [hidden] {
    display: none !important;
  }

  .choices[data-type*="select-one"] {
    cursor: pointer;
  }

  .choices[data-type*="select-one"] .choices__inner {
    padding-bottom: 7.5px;
  }

  .choices[data-type*="select-one"] .choices__input {
    display: block;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    background-color: #fff;
    margin: 0;
  }

  .choices[data-type*="select-one"] .choices__button {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
    padding: 0;
    background-size: 8px;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -10px;
    margin-right: 25px;
    height: 20px;
    width: 20px;
    border-radius: 10em;
    opacity: 0.25;
  }

  .choices[data-type*="select-one"] .choices__button:focus,
  .choices[data-type*="select-one"] .choices__button:hover {
    opacity: 1;
  }

  .choices[data-type*="select-one"] .choices__button:focus {
    box-shadow: 0 0 0 2px #00bcd4;
  }

  .choices[data-type*="select-one"] .choices__item[data-value=""] .choices__button {
    display: none;
  }

  .choices[data-type*="select-one"]::after {
    content: "";
    height: 0;
    width: 0;
    border-style: solid;
    border-color: #333 transparent transparent;
    border-width: 5px;
    position: absolute;
    right: 11.5px;
    top: 50%;
    margin-top: -2.5px;
    pointer-events: none;
  }

  .choices[data-type*="select-one"].is-open::after {
    border-color: transparent transparent #333;
    margin-top: -7.5px;
  }

  .choices[data-type*="select-one"][dir="rtl"]::after {
    left: 11.5px;
    right: auto;
  }

  .choices[data-type*="select-one"][dir="rtl"] .choices__button {
    right: auto;
    left: 0;
    margin-left: 25px;
    margin-right: 0;
  }

  .choices[data-type*="select-multiple"] .choices__inner,
  .choices[data-type*="text"] .choices__inner {
    cursor: text;
  }

  .choices[data-type*="select-multiple"] .choices__button,
  .choices[data-type*="text"] .choices__button {
    position: relative;
    display: inline-block;
    margin: 0-4px 0 8px;
    padding-left: 16px;
    border-left: 1px solid #008fa1;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
    background-size: 8px;
    width: 8px;
    line-height: 1;
    opacity: 0.75;
    border-radius: 0;
  }

  .choices[data-type*="select-multiple"] .choices__button:focus,
  .choices[data-type*="select-multiple"] .choices__button:hover,
  .choices[data-type*="text"] .choices__button:focus,
  .choices[data-type*="text"] .choices__button:hover {
    opacity: 1;
  }

  .choices__inner {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    background-color: #f9f9f9;
    padding: 7.5px 7.5px 3.75px;
    border: 1px solid #ddd;
    border-radius: 2.5px;
    font-size: 14px;
    min-height: 44px;
    overflow: hidden;
  }

  .is-focused .choices__inner,
  .is-open .choices__inner {
    border-color: #b7b7b7;
  }

  .is-open .choices__inner {
    border-radius: 2.5px 2.5px 0 0;
  }

  .is-flipped.is-open .choices__inner {
    border-radius: 0 0 2.5px 2.5px;
  }

  .choices__list {
    margin: 0;
    padding-left: 0;
    list-style: none;
  }

  .choices__list--single {
    display: inline-block;
    padding: 4px 16px 4px 4px;
    width: 100%;
  }

  [dir="rtl"] .choices__list--single {
    padding-right: 4px;
    padding-left: 16px;
  }

  .choices__list--single .choices__item {
    width: 100%;
  }

  .choices__list--multiple {
    display: inline;
  }

  .choices__list--multiple .choices__item {
    display: inline-block;
    vertical-align: middle;
    border-radius: 20px;
    padding: 4px 10px;
    font-size: 12px;
    font-weight: 500;
    margin-right: 3.75px;
    margin-bottom: 3.75px;
    background-color: #00bcd4;
    border: 1px solid #00a5bb;
    color: #fff;
    word-break: break-all;
    box-sizing: border-box;
  }

  .choices__list--multiple .choices__item[data-deletable] {
    padding-right: 5px;
  }

  [dir="rtl"] .choices__list--multiple .choices__item {
    margin-right: 0;
    margin-left: 3.75px;
  }

  .choices__list--multiple .choices__item.is-highlighted {
    background-color: #00a5bb;
    border: 1px solid #008fa1;
  }

  .is-disabled .choices__list--multiple .choices__item {
    background-color: #aaa;
    border: 1px solid #919191;
  }

  .choices__list--dropdown,
  .choices__list[aria-expanded] {
    visibility: hidden;
    z-index: 1;
    position: absolute;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ddd;
    top: 100%;
    margin-top: -1px;
    border-bottom-left-radius: 2.5px;
    border-bottom-right-radius: 2.5px;
    overflow: hidden;
    word-break: break-all;
    will-change: visibility;
  }

  .is-active.choices__list--dropdown,
  .is-active.choices__list[aria-expanded] {
    visibility: visible;
  }

  .is-open .choices__list--dropdown,
  .is-open .choices__list[aria-expanded] {
    border-color: #b7b7b7;
  }

  .is-flipped .choices__list--dropdown,
  .is-flipped .choices__list[aria-expanded] {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: -1px;
    border-radius: 0.25rem 0.25rem 0 0;
  }

  .choices__list--dropdown .choices__list,
  .choices__list[aria-expanded] .choices__list {
    position: relative;
    max-height: 300px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    will-change: scroll-position;
  }

  .choices__list--dropdown .choices__item,
  .choices__list[aria-expanded] .choices__item {
    position: relative;
    padding: 10px;
    font-size: 14px;
  }

  [dir="rtl"] .choices__list--dropdown .choices__item,
  [dir="rtl"] .choices__list[aria-expanded] .choices__item {
    text-align: right;
  }

  @media (min-width: 640px) {

    .choices__list--dropdown .choices__item--selectable,
    .choices__list[aria-expanded] .choices__item--selectable {
      padding-right: 100px;
    }

    .choices__list--dropdown .choices__item--selectable::after,
    .choices__list[aria-expanded] .choices__item--selectable::after {
      content: attr(data-select-text);
      font-size: 12px;
      opacity: 0;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }

    [dir="rtl"] .choices__list--dropdown .choices__item--selectable,
    [dir="rtl"] .choices__list[aria-expanded] .choices__item--selectable {
      text-align: right;
      padding-left: 100px;
      padding-right: 10px;
    }

    [dir="rtl"] .choices__list--dropdown .choices__item--selectable::after,
    [dir="rtl"] .choices__list[aria-expanded] .choices__item--selectable::after {
      right: auto;
      left: 10px;
    }
  }

  .choices__list--dropdown .choices__item--selectable.is-highlighted,
  .choices__list[aria-expanded] .choices__item--selectable.is-highlighted {
    background-color: #f2f2f2;
  }

  .choices__list--dropdown .choices__item--selectable.is-highlighted::after,
  .choices__list[aria-expanded] .choices__item--selectable.is-highlighted::after {
    opacity: 0.5;
  }

  .choices__item {
    cursor: default;
  }

  .choices__item--selectable {
    cursor: pointer;
  }

  .choices__item--disabled {
    cursor: not-allowed;
    -webkit-user-select: none;
    user-select: none;
    opacity: 0.5;
  }

  .choices__heading {
    font-weight: 600;
    font-size: 12px;
    padding: 10px;
    border-bottom: 1px solid #f7f7f7;
    color: gray;
  }

  .choices__button {
    text-indent: -9999px;
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }

  .choices__button:focus,
  .choices__input:focus {
    outline: 0;
  }

  .choices__input {
    display: inline-block;
    vertical-align: baseline;
    background-color: #f9f9f9;
    font-size: 14px;
    margin-bottom: 5px;
    border: 0;
    border-radius: 0;
    max-width: 100%;
    padding: 4px 0 4px 2px;
  }

  .choices__input::-webkit-search-cancel-button,
  .choices__input::-webkit-search-decoration,
  .choices__input::-webkit-search-results-button,
  .choices__input::-webkit-search-results-decoration {
    display: none;
  }

  .choices__input::-ms-clear,
  .choices__input::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  [dir="rtl"] .choices__input {
    padding-right: 2px;
    padding-left: 0;
  }

  .choices__placeholder {
    opacity: 0.5;
  }

  .form-control {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  textarea.form-control {
    resize: vertical;
  }

  /* Button styles */
  .btn {
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
  }

  .btn-primary {
    background-color: #1b0d34;
    color: #fff;
  }

  .btn-primary:hover {
    background-color: white;
    color: #1b0d34;
  }

  /* Error message styles */
  .invalid-feedback {
    color: #dc3545;
    /* Red color for error messages */
  }

  /* Checkbox styles */
  .checkbox {
    margin-bottom: 10px;
  }

  /* File upload styles */
  .fileSelector {
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    cursor: pointer;
  }

  /* Dropdown select styles */
  .choices__input {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  /* Styling for checkbox labels */
  .checkbox.form-check.radio-selected input[type="checkbox"] {
    border: none;
    outline: none;
    padding: 2px;
  }

  .checkbox.form-check.radio-selected input[type="checkbox"]:checked,
  .form-check-input[type="checkbox"]:checked {
    --tw-text-opacity: 1;
    background-color: rgb(27 13 52 / var(--tw-text-opacity));
    border: 1px solid white !important;
    outline: none !important;
    -webkit-appearance: none;
    appearance: none;
  }

  .checkbox.form-check input[type="checkbox"] {
    outline: none !important;
    box-shadow: none !important;
  }

  .checkbox.form-check input[type="checkbox"]:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  /* Responsive styles */
  @media (max-width: 768px) {
    .formio-form {
      width: 100%;
      padding: 10px;
      display: inline !important;
    }
  }

  .formio-form {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: auto;
    gap: 20px;
  }

  .formio-form>div {
    width: auto;
  }

  .formio-form>div:nth-child(n + 7) {
    grid-column: span 3;
  }
}

.priv_btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.bottom_btns {
  display: flex;
  align-items: space-between;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}

.bottom_btns>div {
  width: 100%;
}

.terms_and_cond {
  font-size: 0.75rem;
  max-width: 250px;
  margin-left: 10px;
}

@media (max-width: 768px) {
  .contact_form {
    .formio-form {
      grid-template-columns: repeat(1, 1fr);
    }

    .formio-form>div:nth-child(n + 7) {
      grid-column: auto;
    }
  }

  .bottom_btns {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 30px;
  }

  .bottom_btns>div {
    max-width: 90vw;
  }
}

.formio-component-whichWouldYouConsiderYourselfAs {
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 1.5rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.formio-component-whichWouldYouConsiderYourselfAs>label,
.formio-component-whichWouldYouConsiderYourselfAs .form-check-label {
  margin-right: 10px;
}

.formio-component-whichWouldYouConsiderYourselfAs .form-radio {
  display: flex;
  align-items: center;
}

.formio-component-whichWouldYouConsiderYourselfAs .checkbox {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.formio-form textarea {
  font-size: 1rem;
  line-height: 1.5rem;
  border: 1px solid white;
  width: 100%;
  height: 247px;
  background-color: transparent;
  color: white;
  outline: none;
  padding: 8px 10px;
}

.formio-form textarea::placeholder {
  color: white;
}

.formio-component-byClickingSubmitYouAreAgreeingToOurPrivacyPolicy {
  font-size: 1rem;
}

.formio-component-byClickingSubmitYouAreAgreeingToOurPrivacyPolicy .form-check-label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
}

.list-group-item.list-group-header {
  display: none;
}

.formio-component-submit button {
  background-color: transparent;
  border: 2px solid white;
  color: white;
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding: 8px 20px;
}

.formio-component-submit button:not([disabled]):hover {
  --tw-text-opacity: 1;
  color: rgb(27 13 52 / var(--tw-text-opacity));
  background-color: white;
  border-color: white;
}

.fileSelector {
  background: rgba(255, 255, 255, 0.254);
  padding: 40px;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(27 13 52 / var(--tw-text-opacity));
  font-size: 1rem;

  .browse {
    background-color: white;
    padding: 0.5rem 1rem;
  }

  .fileBrowse {
    background-color: white;
    padding: 0.5rem 1rem;
  }
}

.button_privacy {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header {
  .logo {
    transition: all 0.3s ease;
  }

  .job-search-btn {
    transition: all 0.3s ease;
  }

  &.scrolled {
    padding-top: 1rem;
    padding-bottom: 1rem;

    .logo {
      max-width: 120px;
    }

    .job-search-btn {
      @media (min-width: 767px) {
        padding: 0.5rem 1rem !important;
        min-width: 150px;

        &:hover,
        &:focus,
        &:active {
          padding: 0.5rem 2.5rem 0.5rem 1rem !important;

        }
      }
    }
  }
}

.footer-brands {
  .image-container {
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 767px) {
    margin-bottom: 3rem;
  }
}

.bg-aboutBanner {
  background-position: right 35%, left 88% !important;
}

.operational-swiper-container {
  >div {
    height: 100%;
  }

  div {
    .cards {
      height: 100%;

      img {
        object-fit: cover;
      }
    }
  }
}

.specialism-page {
  .latest-blogs {
    p {
      color: #fff;
    }
  }
}

.sr-only {
  display: none;
}

#CaseStudyPage {
  .content {

    h1,
    h2,
    h3,
    h4,
    h5 {
      color: #1b0d34;
    }
  }
}

// candidates page
.ColoredHeader.container {
  img {
    transition: transform 0.3s ease;
  }

  h1 span {
    transition: color 0.5s ease;
    transform: scale(0.9);
  }

  @keyframes glowAnimation {
    0% {
      text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
    }

    50% {
      text-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
    }

    100% {
      text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
    }
  }

  //h1 span:hover {
  //  animation: glowAnimation 1s infinite linear;
  //  transform: scale(1.1);
  //}

  // a {
  //   transition: background-color 0.5s ease, color 0.5s ease, transform 0.5s ease;
  //   transform: scale(1);
  // }

  // a:hover {
  //   background-color: white;
  //   color: purple;
  //   transform: scale(1.1);
  // }
}

.CustomSourceFlowText_text span {
  transition: all 0.25s ease;
}

@keyframes wiggle {
  20% {
    transform: translateX(4px);
  }

  40% {
    transform: translateX(-4px);
  }

  60% {
    transform: translateX(2px);
  }

  80% {
    transform: translateX(-1px);
  }

  100% {
    transform: translateX(0);
  }
}

.CustomSourceFlowText_text span:hover {
  animation: wiggle 1s ease;
  animation-iteration-count: 1;
}

.about_stck_title {
  line-height: 3rem;
}

.about_width_60 {
  max-width: 900px;
}

.featured-blog {
  animation: scaleInEaseIn 0.3s ease-in forwards;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes scaleInEaseIn {
  from {
    transform: scale(0.97);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

.blog_img_height {
  height: 607px;

  @media (max-width: 900px) {
    height: 407px;
  }
}

/* Hide scrollbar */
.no-scrollbar::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  -webkit-overflow-scrolling: touch;
  /* Smooth scrolling on mobile */
}

/* Fading effect */
.relative {
  position: relative;
}

.fade-left {
  left: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 90px;
  pointer-events: none;
  opacity: 0.6;
  background: linear-gradient(to left,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1));
}

.fade-right {
  right: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 90px;
  pointer-events: none;
  opacity: 0.2;
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1));
}

.glide__slide img {
  transition: all 0.3s ease;
}

.glide__slide .active-image {
  width: 500px;
  /* Adjust width as needed */
  height: 700px;
  object-fit: cover;
  opacity: 1;

  @media (max-width: 768px) {
    width: 100%;
    height: 500px;
  }
}

.glide__slide .inactive-image {
  width: 500px;
  /* Adjust width as needed */
  height: 600px;
  transform: translateY(100px);
  object-fit: cover;
  opacity: 0.5;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 100%;
    height: 400px;
  }
}

.glide__slide.active {
  opacity: 1;
}

.glide__slide.inactive {
  opacity: 0.5;
}

.cards_wrapper {
  gap: 130px;

  @media (max-width: 1100px) {
    gap: 60px;
  }
}

.details {

  .summary>.active-vector {
    animation: rotation 0.15s linear forwards;
  }

  .details-container:not(:hover),
  .summary-link:not(:hover) {
    color: #8e8e8e !important;
  }

  .summary-link {
    opacity: 1;
  }
}

.job_search {

  div,
  span,
  button {
    font-family: var(--popins);
  }

  .js-container {
    background-color: #453562;
    position: relative;
    max-height: 317px;
    padding-bottom: 20px;

    .js-panel-border {
      border: none;
    }

    .js-form-input-wrapper {
      gap: 50px;
      align-items: end;

      .js-button {
        display: flex;
        justify-content: center;
        background-color: #52ff00;
        font-size: 20px;
        align-items: center;
        min-width: 50px;
        border: none;
        width: 100%;
        border-radius: 0;
        max-width: 165px;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        color: #1b0d34;
        margin-left: auto;
        font-weight: 600;
      }

      input {
        background-color: transparent;
        border: none;
        font-size: 30px;
        font-weight: 400;
        line-height: 38px;
        color: white;
        border-bottom: 1px solid #ffffff33;
        padding-bottom: 15px;
        padding-left: 0;
        font-family: var(--popins);

        &::placeholder {
          font-size: 30px;
          font-weight: 400;
          line-height: 38px;
          color: white;
          font-family: var(--popins);
        }

        &:focus {
          box-shadow: none;
          border: none;
          border-bottom: 1px solid #ffffff33;
        }
      }
    }

    .js-columns-container {
      flex-direction: column;

      .js-reset-search-filter-box {
        position: absolute;
        bottom: 0;
        right: 20px;
        display: block;
        background-color: transparent !important;
        z-index: 1000;

        .js-button.js-reset-search {
          background-color: transparent;
          border: none;
          font-size: 16px;
          font-weight: 400;
          line-height: 30px;
          color: #b8b8b8;
          text-indent: -9999px;
          width: 110px;
          white-space: nowrap;
          overflow: hidden;
          margin-bottom: 4px;
        }
      }

      .js-results {
        display: flex;
        justify-content: center;
        margin-left: 0px;
        margin-top: 25px;
      }
    }

    .js-total-count {
      display: none;
    }

    .js-filters-collapse {
      display: flex;
      gap: 19px;
      width: 100%;
      overflow: visible;

      @media (max-width: 767px) {
        flex-wrap: wrap;
        gap: 10px;
      }

      .js-filter-title-button {
        width: calc(100% - 60px);
      }

      .js-filter-box.js-panel-border:has(.js-filter-selected-class) {
        background-color: #51fb06;
      }

      .js-filter-box:not(.js-reset-search-filter-box) {
        margin-top: 0;
        height: max-content;
        background-color: white;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        color: #1b0d34;
        border-radius: 0;
        padding: 0;
        z-index: 10;
        gap: 0;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        flex-grow: 1;
        flex-shrink: 1;
        width: 25%;

        @media (max-width: 860px) {
          width: 0;
        }

        @media (max-width: 767px) {
          width: 100%;
        }

        .js-filter-category-closed {
          display: block;
          visibility: hidden;
          opacity: 0;
          height: 0;
          transition: none;
        }

        .js-filter-title-open {
          height: auto;
        }

        .js-filter-title {
          margin-bottom: 0;
          position: relative;
          z-index: 10;

          .js-filter-title-button {
            padding: 20px 40px 20px 20px;
            white-space: nowrap;
            scale: inherit;

            @media (max-width: 860px) {
              padding: 20px 30px 20px 10px;
            }
          }

          &::before {
            content: "";
            position: absolute;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%231B0D34' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
            background-position: left;
            background-repeat: no-repeat;
            background-size: 1.5em 1.5em;
            top: 25px;
            font-size: 21px;
            width: 105px;
            height: 15px;
            font-weight: 900;
            right: 0px;
            z-index: -1;
            transform: rotate(180deg);
          }

          .js-filter-title-button {
            &:hover {
              scale: inherit;
            }
          }
        }

        .js-filter-title-closed {
          opacity: 1;

          &::before {
            transform: rotate(0deg);
            background-position: right;
          }
        }

        .js-filter-value {
          width: 100%;

          &:hover {
            scale: inherit;
          }
        }

        .js-filter-value-list-item {
          margin-bottom: 0;
        }

        .js-filter-value-content {
          padding: 15px 40px 15px 20px;
          width: 100%;

          justify-content: start;

          .js-filter-selected {
            display: none;
          }
          .js-filter-value-name {
            flex-grow: 1;
        }
          .js-filter-count {
            padding: 5px 7.5px;
            font-size: 0.75rem;
            border: 0;
            width: auto;
            height: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #1b0d34;
          }
        }
      }
    }

    .js-filters-column {
      padding: 0 2rem;
      display: flex;
      flex-wrap: wrap;

      .js-button.js-filters-toggle-button {
        background-color: #52ff00;
        border: #52ff00;
        font-weight: 600;
        line-height: 21px;
        position: relative;
        color: #1b0d34;
      }
    }

    .js-pagination {
      display: none;
    }
  }
}

@media (max-width: 945px) {
  .js-container {
    max-height: max-content !important;
  }
}

@media (max-width: 767px) {
  .job_search {
    .js-container {
      padding-bottom: 40px !important;
      max-height: none;
    }

    .js-filters-collapse {
      width: 100% !important;
      flex-direction: column;
    }

    .js-button.js-filters-toggle-button {
      &::before {
        content: " ";
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%231B0D34' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
        background-position: right 0.5rem center;
        background-repeat: no-repeat;
        background-size: 1.5em 1.5em;
        position: absolute;
        width: 105px;
        height: 15px;
        right: 0;
        top: 9px;
      }
    }

    .js-panel-border {
      padding: 2rem 2rem 0;
    }

    .js-form-input-wrapper {
      input {
        font-size: 20px !important;

        &::placeholder {
          font-size: 20px !important;
        }
      }

      gap: 20px !important;

      .js-button {
        font-size: 14px !important;
        padding: 0 !important;
        height: 45px !important;
        max-width: 120px !important;
      }
    }

    .js-filters-collapse {
      overflow: hidden !important;
    }

    .js-filter-category-closed {
      display: none;
    }

    .js-filter-title {
      &::before {
        top: 18px !important;
      }
    }

    .js-filter-title-button {
      width: 100% !important;
      padding: 12px 20px !important;
    }

    .js-reset-search-filter-box {
      right: 5px !important;

      .js-reset-search {
        font-size: 12px !important;

      }
    }

    .js-filters-column {
      margin-bottom: 0 !important;
    }

    .js-results {
      margin-top: 0 !important;
    }
  }
}

// temp pages

#CaseStudiesPage {
  padding-bottom: 100px;
}

#CaseStudyPage {
  padding-bottom: 100px;

  .content {
    p {
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 160%;
      color: #1b0d34;
    }

    h3 {
      font-weight: 400;
      font-size: 2.5rem;
      line-height: 120%;
      color: #1b0d34;
    }
  }
}

#BlogArticleContent {
  padding-bottom: 100px;

  .content {
    h1 {
      font-weight: 400;
      font-size: 3rem;
      line-height: 120%;
      color: #1b0d34;
    }

    h2 {
      font-weight: 400;
      font-size: 2.5rem;
      line-height: 120%;
      color: #1b0d34;
    }

    h3 {
      font-weight: 400;
      font-size: 2rem;
      line-height: 120%;
      color: #1b0d34;
    }

    h4 {
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 120%;
      color: #1b0d34;
    }

    h5 {
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 120%;
      color: #1b0d34;
    }

    h6 {
      font-weight: 400;
      font-size: 1rem;
      line-height: 120%;
      color: #1b0d34;
    }

    p {
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 160%;
      color: #1b0d34;
    }

    strong {
      font-weight: bold;
    }

    em {
      font-style: italic;
    }

    a {
      color: #007bff;
    }

    ul,
    ol {
      font-size: 1.25rem;
      line-height: 160%;
      color: #1b0d34;
      margin-left: 20px;
      list-style-type: decimal;
    }

    li {
      font-size: 1.25rem;
      line-height: 160%;
      color: #1b0d34;
      margin-left: 20px;
      list-style-type: disc;
    }

    blockquote {
      font-style: italic;
      border-left: 4px solid #1b0d34;
      padding-left: 15px;
    }

    code {
      font-family: monospace;
      background-color: #f8f9fa;
      padding: 2px 5px;
    }
  }
}

.specialism-content {
  #dynamicContent {
    a {
      color: #1b0d34;

      &:hover,
      &:focus,
      &:active {
        text-decoration: underline;
      }
    }
  }

}

.home-page-hero-text {
  p span[data-sourceflow-type="text"] {
    @media (max-width: 480px) {
      font-size: 50px;
      line-height: 50px;
    }
  }

  .animate-typing span {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 480px) {
      font-size: 32px;
      line-height: 30px !important;
      height: 60px;
    }
  }
}

.job-search-btn {

  &:hover,
  &:focus,
  &:active {}

  @media (max-width: 639px) {
    padding-left: 0 !important;
    padding-right: 0 !important;

    .small-arrow {
      display: none;
    }

  }
}

.case-studies {
  a {

    &:hover,
    &:focus,
    &:active {
      .description {
        opacity: 1;
      }

      .image-wrapper {
        top: 30px;
        left: -30px;
        transform: none;
        width: 300px;
      }
    }
  }
}

.all-case-studies {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;

  .column {
    width: 100%;

    a {

      &:hover,
      &:focus,
      &:active {
        .description {
          opacity: 1;
        }

        .image-wrapper {
          top: 30px;
          left: -30px;
          transform: none;
          width: 300px;
        }
      }
    }
  }

  .client_card_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 991px) {
    gap: 150px;

    .column {
      width: calc((100%/2) - 75px);
    }
  }
}

.clients-page {
  &:after {
    content: "";
    background-image: url("/site-assets/clients/background-part-left.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 800px;
    height: 800px;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    bottom: -150px;
  }

  .right-corner-img {
    display: none;
  }

  .our-services {


    .sub-card {
      background: transparent;

      &:hover,
      &:focus,
      &:active {
        background: #ffffff;
      }
    }
  }
}







