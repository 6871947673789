.accordion_toggle_btn_wrapper {
  display: flex;
  svg {
    height: 38px;
    width: 38px;
  }
}
.accordion_toggle_btn_title {
  line-height: 4rem;
}
.accord_open_icon svg {
  transform: rotate(45deg);
}

.join-us-accordion {
  .accord_close {
    color: #cecece;
  }
  .accord_open {
    color: #ff1eb7;
  }
  .accord_open_icon svg path {
    fill: white;
  }
  .accord_close_icon svg path {
    fill: white;
  }
}

.clients-accordion {
  .accord_close {
    color: #949494;
  }
  .accord_open {
    color: white;
  }
  .accord_open_icon svg path {
    fill: white;
  }
  .accord_close_icon svg path {
    fill: white;
  }
}

.candidate-accordion {
  .accord_close {
    color: #949494;
  }
  .accord_open {
    color: #1b0d34;
  }
  .accord_open_icon svg path {
    fill: #1b0d34;
  }
  .accord_close_icon svg path {
    fill: #1b0d34;
  }
}
