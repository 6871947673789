@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@import "@glidejs/glide/src/assets/sass/glide.core";
@import "@glidejs/glide/src/assets/sass/glide.theme";

@import "src/styles/custom";
@import "src/styles/accordion";
